<template>
    <div data-app>
      
      <div class="card card-custom h-100">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">
              Edit Chickin
            </h3>
          </div>
        </div>
  
        <!--begin::Form-->
          <div class="card-body">
          <perfect-scrollbar
          class="scroll"
          style="max-height: 98%; position: relative;"
          >
            <ValidationObserver ref="formAdd">
            <b-form @submit="onSubmit" v-if="show">
                <validationProvider :name="`Asal Ternak`" rules="required" v-slot="{ errors, valid }">
                    <b-form-group 
                    :id="`input-group-edit-2`" 
                    label="Asal Ternak:" 
                    :label-for="`input-edit-2`"
                    :invalid-feedback="errors[0]"
                    :state="valid">
                        <b-form-select2
                        :id="`input-edit-2`"
                        v-model="form.asalternak"
                        :options="opsAsalTernak"
                        required
                        variant="solid"
                        :state="valid"
                        ></b-form-select2>
                    </b-form-group>
                    </validationProvider>

                    <validationProvider :name="`Sex`" rules="required" v-slot="{ errors, valid }">
                    <b-form-group 
                    :id="`input-group-edit-2`" 
                    label="Sex:" 
                    :label-for="`input-edit-2`"
                    :invalid-feedback="errors[0]"
                    :state="valid">
                        <b-form-select2
                        :id="`input-edit-2`"
                        v-model="form.sex"
                        :options="opsSex"
                        required
                        variant="solid"
                        :state="valid"
                        ></b-form-select2>
                    </b-form-group>
                    </validationProvider>

                    <validationProvider :name="`Strain`" rules="required" v-slot="{ errors, valid }">
                    <b-form-group 
                    :id="`input-group-edit-3`" 
                    label="Strain:" 
                    :label-for="`input-edit-3`"
                    :invalid-feedback="errors[0]"
                    :state="valid">
                        <b-form-select2
                        :id="`input-edit-3`"
                        v-model="form.strain"
                        :options="opsStrain"
                        required
                        variant="solid"
                        :state="valid"
                        ></b-form-select2>
                    </b-form-group>
                    </validationProvider>

                    <validationProvider :name="`Jumlah`" rules="required|min_value:1|max_value:9999999999" v-slot="{ errors, valid }">
                    <b-form-group 
                    :id="`input-group-edit-4`" 
                    label="Jumlah:" 
                    :label-for="`input-edit-4`"
                    :invalid-feedback="errors[0]"
                    :state="valid">
                        <vue-number-input 
                        :attrs="{ id: `input-edit-4`, class: `b-form-spinbutton form-control form-control-solid d-flex align-items-stretch` }"
                        v-model="form.jumlah"
                        :min="0"
                        :max="9999999999"
                        required
                        center
                        controls
                        ></vue-number-input>
                    </b-form-group>
                    </validationProvider>
  
            </b-form>
            </ValidationObserver>
  
            <div class="mt-3">
                <button 
                  @click="onSubmit" 
                  ref="kt_submit"
                  class="btn btn-primary font-weight-bold"
                >
                  <i class="la la-eye"></i>
                  Simpan
                </button>
                &nbsp;
                <button
                  @click="closeDialog()"
                  class="btn btn-clean font-weight-bold"
                >
                  <i class="la la-recycle"></i>
                  Close
                </button>
            </div>
          </perfect-scrollbar>  
          </div>
        <!--end::Form-->
      </div>
    </div>
  </template>
  
  <script>
  import PopulasiService from "@/core/services/api/nasional/populasi.service";
  import PerusahaanService from "@/core/services/api/master/perusahaan.service";
  import MasterService from "@/core/services/api/master/master.service";
  import ErrorService from "@/core/services/error.service";
  
  export default {
    name: "unggas-nasional-data-populasi-chickin-edit",
    props: {
        sdata: {
            required: true
        }
    },
    data() {
      return {
        loading: false,
        form: {
          populasi: this.sdata._idp,
          chickin: this.sdata._id,
          asalternak: null,
          sex: null,
          strain: null,
          jumlah: null,
        },
        show: true,
        opsAsalTernak: [{
            value: null,
            text: 'Silahkan pilih Asal Ternak',
        }],
        opsSex: [{
            value: null,
            text: 'Silahkan pilih Sex',
        }],
        opsStrain: [{
            value: null,
            text: 'Silahkan pilih Strain',
        }],
      }
    },
    methods: {
      closeDialog() {
          return this.$emit('close-modal', {status: true, refresh: false})
      },
      removeSpinner(button) { 
        button.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      },
      getPerusahaan() {
          PerusahaanService.getPerusahaan()
          .then((res) => {
              if (!res.data.status) {
                  ErrorService.message(res.data)
                  this.opsAsalTernak = [{
                      value: null,
                      text: "Silahkan pilih Perusahaan"
                  }]
                  return;
              }

              this.opsAsalTernak = res.data.data
              this.opsAsalTernak.push({
                  value: null,
                  text: "Silahkan pilih Perusahaan"
              })
          })
          .catch((err) => {
              this.opsAsalTernak = [{
                  value: null,
                  text: "Tidak ada Perusahaan"
              }]
              ErrorService.status(err)
          })
      },
      getTernakSex() {
          MasterService.getTernakSex()
          .then((res) => {
              if (!res.data.status) {
                  ErrorService.message(res.data)
                  this.opsSex = [{
                      value: null,
                      text: "Silahkan pilih Sex"
                  }]
                  return;
              }

              this.opsSex = res.data.data
              this.opsSex.push({
                  value: null,
                  text: "Silahkan pilih Sex"
              })
          })
          .catch((err) => {
              this.opsSex = [{
                  value: null,
                  text: "Tidak ada Sex"
              }]
              ErrorService.status(err)
          })
      },        
      getTernakStrain() {
          MasterService.getTernakStrain()
          .then((res) => {
              if (!res.data.status) {
                  ErrorService.message(res.data)
                  this.opsStrain = [{
                      value: null,
                      text: "Silahkan pilih Strain"
                  }]
                  return;
              }

              this.opsStrain = res.data.data
              this.opsStrain.push({
                  value: null,
                  text: "Silahkan pilih Strain"
              })
          })
          .catch((err) => {
              this.opsStrain = [{
                  value: null,
                  text: "Tidak ada Strain"
              }]
              ErrorService.status(err)
          })
      },
      getData () {
        PopulasiService.getChickinEdit({
          _pid: this.sdata._idp,
          chickin: this.sdata._id,
        })
        .then((res) => {
          if (res.data.status) {
            this.form.populasi = res.data.data._idp
            this.form.chickin = res.data.data._id
            this.form.asalternak = res.data.data._ida
            this.form.sex = res.data.data._idse
            this.form.strain = res.data.data._idst
            this.form.jumlah = res.data.data.jumlah
            return;
          }
          ErrorService.message(res.data)
          this.closeDialog()
        })
        .catch((err) => {
          ErrorService.status(err)
          this.closeDialog()
        })
      },
      onSubmit(evt) {
        evt.preventDefault()
        this.$refs.formAdd.validate().then(success => {
          if (!success) {
              ErrorService.message({
                action: 'Peringatan',
                message: 'Silahkan lengkapi form data terlebih dahulu',
              })
              return;
          }
  
          // set spinner to submit button
          const submitButton = this.$refs["kt_submit"];
          submitButton.classList.add("spinner", "spinner-light", "spinner-right");
  
          // dummy delay
          // setTimeout(() => {
                PopulasiService.updateChickin(this.form)
                  .then((res) => {
                      this.removeSpinner(submitButton)
                      ErrorService.message(res.data)
                      
                      if (res.data.status) {
                        this.$emit('close-modal', {status: true, refresh: true})
                      }
                      return;
                  })
                  .catch((err) => {
                    this.removeSpinner(submitButton)
                    ErrorService.status(err)
                  })
  
          // }, 1000);
        })
      },
    },
    mounted() {
      this.getData();
      this.getPerusahaan()
      this.getTernakSex()
      this.getTernakStrain()
    },
  };
  </script>
  