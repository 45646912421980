<template>
    <div data-app>
      
      <div class="card card-custom h-100">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">
              Edit Afkir
            </h3>
          </div>
        </div>
  
        <!--begin::Form-->
          <div class="card-body">
          <perfect-scrollbar
          class="scroll"
          style="max-height: 98%; position: relative;"
          >
            <ValidationObserver ref="formAdd">
            <b-form @submit="onSubmit" v-if="show">

                <validationProvider :name="`Jumlah`" rules="required|min_value:1|max_value:9999999999" v-slot="{ errors, valid }">
                <b-form-group 
                :id="`input-group-edit-4`" 
                label="Jumlah:" 
                :label-for="`input-edit-4`"
                :invalid-feedback="errors[0]"
                :state="valid">
                    <vue-number-input 
                    :attrs="{ id: `input-edit-4`, class: `b-form-spinbutton form-control form-control-solid d-flex align-items-stretch` }"
                    v-model="form.jumlah"
                    :min="0"
                    :max="9999999999"
                    required
                    center
                    controls
                    ></vue-number-input>
                </b-form-group>
                </validationProvider>
  
            </b-form>
            </ValidationObserver>
  
            <div class="mt-3">
                <button 
                  @click="onSubmit" 
                  ref="kt_submit"
                  class="btn btn-primary font-weight-bold"
                >
                  <i class="la la-eye"></i>
                  Simpan
                </button>
                &nbsp;
                <button
                  @click="closeDialog()"
                  class="btn btn-clean font-weight-bold"
                >
                  <i class="la la-recycle"></i>
                  Close
                </button>
            </div>
          </perfect-scrollbar>  
          </div>
        <!--end::Form-->
      </div>
    </div>
  </template>
  
  <script>
  import PopulasiService from "@/core/services/api/nasional/populasi.service";
  import ErrorService from "@/core/services/error.service";
  
  export default {
    name: "unggas-nasional-data-populasi-afkir-edit",
    props: {
        sdata: {
            required: true
        }
    },
    data() {
      return {
        loading: false,
        form: {
          _pid: this.sdata._idp,
          _id: this.sdata._idc,
          afkir: this.sdata._id,
          jumlah: null,
        },
        show: true,
      }
    },
    methods: {
      closeDialog() {
          return this.$emit('close-modal', {status: true, refresh: false})
      },
      removeSpinner(button) { 
        button.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      },
      getData () {
        PopulasiService.getAfkirEdit({
          _pid: this.sdata._idp,
          _cid: this.sdata._idc,
          afkir: this.sdata._id,
        })
        .then((res) => {
          if (res.data.status) {
            this.form._pid = res.data.data._idp
            this.form._id = res.data.data._idc
            this.form.afkir = res.data.data._id
            this.form.jumlah = res.data.data.jumlah
            return;
          }
          ErrorService.message(res.data)
          this.closeDialog()
        })
        .catch((err) => {
          ErrorService.status(err)
          this.closeDialog()
        })
      },
      onSubmit(evt) {
        evt.preventDefault()
        this.$refs.formAdd.validate().then(success => {
          if (!success) {
              ErrorService.message({
                action: 'Peringatan',
                message: 'Silahkan lengkapi form data terlebih dahulu',
              })
              return;
          }
  
          // set spinner to submit button
          const submitButton = this.$refs["kt_submit"];
          submitButton.classList.add("spinner", "spinner-light", "spinner-right");
  
          // dummy delay
          // setTimeout(() => {
                PopulasiService.updateAfkir(this.form)
                  .then((res) => {
                      this.removeSpinner(submitButton)
                      ErrorService.message(res.data)
                      
                      if (res.data.status) {
                        this.$emit('close-modal', {status: true, refresh: true})
                      }
                      return;
                  })
                  .catch((err) => {
                    this.removeSpinner(submitButton)
                    ErrorService.status(err)
                  })
  
          // }, 1000);
        })
      },
    },
    mounted() {
      this.getData();
    },
  };
  </script>
  